
import { defineComponent, onMounted, reactive, ref } from "vue";
import XAtable from "@/components/XATable.vue";
import {
  userQueryPageList,
  sysNovelConfigList,
  userAddAppUser,
  userUpdateAppUser,
} from "@/api/api";
import XDialog from "@/components/XDialog.vue";
import type { Rule } from "ant-design-vue/es/form";
import type { FormInstance } from "ant-design-vue";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

type RangeValue = [Dayjs, Dayjs];

interface SearchFormState {
  mobile: String;
  //   time: RangeValue;
}

interface FormState {
  name: String;
  mobile: String;
  company: String;
  status: Number;
  wallet: String;
  id: String;
}
export default {
  components: {
    XAtable,
    XDialog,
  },
  setup() {
    const searchFormState = reactive<SearchFormState>({
      mobile: "",
      //   time: [
      //     dayjs(
      //       new Date(dayjs().year(), dayjs().month(), dayjs().get("date") - 180)
      //     ),
      //     dayjs(),
      //   ],
    });
    const formState = reactive<FormState>({
      name: "",
      mobile: "",
      status: 1,
      wallet: "",
      company: "",
      id: "",
    });
    const isAdd = ref(1);
    const dislogVisible = ref(false);
    const addLoading = ref(false);
    const pageNo = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const appList = ref([]);
    const dateFormat = ref("YYYY-MM-DD");
    const loading = ref(false);
    const columns = reactive([
      {
        title: "ID",
        key: "id",
        dataIndex: "id",
      },
      {
        title: "用户名",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "手机号",
        key: "mobile",
        dataIndex: "mobile",
      },
      {
        title: "公司",
        key: "company",
        dataIndex: "company",
      },
      {
        title: "余额($)",
        key: "wallet",
        dataIndex: "wallet",
      },
      {
        title: "状态",
        key: "status",
        dataIndex: "status",
        slots: {
          customRender: "status",
        },
      },
      {
        title: "操作",
        key: "control",
        dataIndex: "control",
        slots: {
          customRender: "control",
        },
      },
    ]);
    const tableData = ref([]);
    const formRef = ref<FormInstance>();
    const topUpDislogVisible = ref(false);
    const topUpLoading = ref(false);

    const search = () => {
      pageNo.value = 1;
      getData();
    };
    const resetForm = () => {
      searchFormState.mobile = "";
      //   searchFormState.time = [
      //     dayjs(
      //       new Date(dayjs().year(), dayjs().month(), dayjs().get("date") - 180)
      //     ),
      //     dayjs(),
      //   ];
      pageNo.value = 1;
      getData();
    };
    const getAppList = () => {
      loading.value = true;
      sysNovelConfigList({}).then((res) => {
        appList.value = res.data;
        // searchFormState.appId = res.data[0].id;
        getData();
      });
    };
    const getData = () => {
      loading.value = true;
      var parms = {
        // beginTime:
        //   dayjs(searchFormState.time[0]).format(dateFormat.value) + " 00:00:00",
        // endTime:
        //   dayjs(searchFormState.time[1]).format(dateFormat.value) + " 00:00:00",
        // id: searchFormState.appId ? searchFormState.appId : null,
        mobile: searchFormState.mobile,
        pageNo: pageNo.value,
        pageSize: pageSize.value,
      };
      userQueryPageList(parms).then((res) => {
        loading.value = false;
        total.value = res.data.total;
        tableData.value = res.data.records;
      });
    };
    const pageNoChange = (val) => {
      pageNo.value = val;
      getData();
    };
    const onShowSizeChange = (current, size) => {
      pageSize.value = size;
      getData();
    };
    const selectChange = () => {
      pageNo.value = 1;
      getData();
    };
    const addClick = () => {
      isAdd.value = 1;
      dislogVisible.value = true;
      formState.name = "";
      formState.mobile = "";
      formState.company = "";
      formState.id = "";
      formState.status = 1;
      formState.wallet = "";
    };
    const editClick = (row: any) => {
      isAdd.value = 0;
      for (const key in formState) {
        formState[key] = row[key];
      }
      dislogVisible.value = true;
    };
    const confirm = async () => {
      try {
        const values = await formRef.value.validateFields();
        addLoading.value = true;

        if (isAdd.value === 1) {
          formState.id = "";
          userAddAppUser(formState).then((res) => {
            addLoading.value = false;
            if (res.code === "200") {
              dislogVisible.value = false;
              getData();
            }
          });
        } else {
          userUpdateAppUser(formState).then((res) => {
            addLoading.value = false;
            if (res.code === "200") {
              dislogVisible.value = false;
              getData();
            }
          });
        }
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const topUptoUser = (row: any) => {
      for (const key in formState) {
        formState[key] = row[key];
      }
      formState.wallet = "";
      topUpDislogVisible.value = true;
    };
    const topUpConfirm = () => {
      try {
        topUpLoading.value = true;
        userUpdateAppUser(formState).then((res) => {
          topUpLoading.value = false;
          if (res.code === "200") {
            topUpDislogVisible.value = false;
            getData();
          }
        });
      } catch {}
    };
    const limitAmount = (event: any) => {
      formState.wallet = event.target.value
        .replace(/[^\d.]/g, "")
        // 限制只有一个小数点
        .replace(/^\./g, "")
        .replace(/\.{2,}/g, ".")
        // 限制小数点后只能有两位
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".")
        .replace(/^(\-)*(\d+)\.(\d{2}).*$/, "$1$2.$3");
    };
    onMounted(() => {
      getData();
    });
    return {
      isAdd,
      formRef,
      dislogVisible,
      addClick,
      confirm,
      topUpConfirm,
      searchFormState,
      formState,
      addLoading,
      columns,
      appList,
      tableData,
      pageNo,
      dateFormat,
      total,
      loading,
      topUpDislogVisible,
      topUpLoading,
      search,
      resetForm,
      pageNoChange,
      onShowSizeChange,
      selectChange,
      editClick,
      topUptoUser,
      limitAmount,
    };
  },
};

import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/sysUser/login',
        method: 'post',
        data
    })
}
// 重置密码
export function resetPassword(data: any) {
    return request({
        url: '/sysUser/updatePass',
        method: 'post',
        data
    })
}
// 退出登录
export function loginOut(data: any) {
    return request({
        url: '/sysUser/logout',
        method: 'post',
        data
    })
}
// 投放数据列表
export function sysNovelUserOrderList(data: any) {
    return request({
        url: '/sysNovelUserOrder/queryList',
        method: 'get',
        params: data
    })
}
// 投放数据修改
export function sysNovelUserOrderSave(data: any) {
    return request({
        url: '/sysNovelUserOrder/save',
        method: 'post',
        data
    })
}
// 应用列表 
export function sysNovelConfigList(data: any) {
    return request({
        url: '/sysNovelConfig/queryListAll',
        method: 'get',
        params: data
    })
}
// 小说数据 
export function sysNovelUserOrderDataList(data: any) {
    return request({
        url: '/sysNovelUserOrder/data/queryList',
        method: 'get',
        params: data
    })
}
// 首页数据 
export function sysNovelUserOrderHome(data: any) {
    return request({
        url: '/sysNovelUserOrder/home/queryList',
        method: 'get',
        params: data
    })
}
// 首页数据列表 
export function sysNovelUserOrderHomeListBottom(data: any) {
    return request({
        url: '/sysNovelUserOrder/home/queryListBottom',
        method: 'get',
        params: data
    })
}
// 保存数据 
export function sysNovelConfigSave(data: any) {
    return request({
        url: '/sysNovelConfig/save',
        method: 'post',
        data
    })
}
// 删除数据 
export function sysNovelConfigDel(data: any) {
    return request({
        url: '/sysNovelConfig/del',
        method: 'post',
        data
    })
}
//用户列表
export function userQueryPageList(data: any) {
    return request({
        url: '/appUser/queryPageList',
        method: 'get',
        params: data
    })
}
//用户新增
export function userAddAppUser(data: any) {
    return request({
        url: '/appUser/addAppUser',
        method: 'post',
        data
    })
}
//用户更新
export function userUpdateAppUser(data: any) {
    return request({
        url: '/appUser/updateAppUser',
        method: 'post',
        data
    })
}
//广告账户管理
export function sysAdsQueryList(data: any) {
    return request({
        url: '/sysAds/queryList',
        method: 'get',
        params: data
    })
}
//广告账户更新
export function sysAdsSave(data: any) {
    return request({
        url: '/sysAds/save',
        method: 'post',
        data
    })
}
//支付管理
export function sysPayQueryList(data: any) {
    return request({
        url: '/sysPay/queryList',
        method: 'get',
        params: data
    })
}
//删除列表
export function sysPayDelList(data: any) {
    return request({
        url: '/sysAds/delList',
        method: 'get',
        params: data
    })
}
//删除列表删除
export function sysPayDelListdel(data: any) {
    return request({
        url: '/sysAds/del',
        method: 'post',
        data
    })
}
//审核到账
export function sysPayToAds(data: any) {
    return request({
        url: '/sysPay//toAds',
        method: 'post',
        data
    })
}
//审核退回
export function sysPayReAds(data: any) {
    return request({
        url: '/sysPay/reAds',
        method: 'post',
        data
    })
}
//bm列表
export function bmQueryList(data: any) {
    return request({
        url: '/sysBm/queryList',
        method: 'get',
        params: data
    })
}


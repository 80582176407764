
import {
  PieChartOutlined,
  DesktopOutlined,
  UserOutlined,
  TeamOutlined,
  FileOutlined,
} from "@ant-design/icons-vue";
import { ref } from "vue";

export default {
  components: {
    PieChartOutlined,
    DesktopOutlined,
    UserOutlined,
    TeamOutlined,
    FileOutlined,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    collapsed: {
      handler(newVal, oldVal) {
        console.log(newVal);
        this.visible = newVal;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selectedKeys: [],
      visible: false,
    };
  },
  mounted() {
    this.selectedKeys = [this.$route.path];
  },
  methods: {
    menuClick(item) {
      this.$router.push(item.key);
      this.$emit("close");
    },
  },
};

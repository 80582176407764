import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fb8a32f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_DownOutlined = _resolveComponent("DownOutlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_x_dialog = _resolveComponent("x-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        (_ctx.ccollapsed)
          ? (_openBlock(), _createBlock(_component_menu_unfold_outlined, {
              key: 0,
              class: "trigger",
              onClick: _ctx.ccollapsedClick
            }, null, 8, ["onClick"]))
          : (_openBlock(), _createBlock(_component_menu_fold_outlined, {
              key: 1,
              class: "trigger",
              onClick: _ctx.ccollapsedClick
            }, null, 8, ["onClick"]))
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_a_dropdown, null, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      href: "javascript:;",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.editPassword && _ctx.editPassword(...args)))
                    }, "修改密码")
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      href: "javascript:;",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.loginOutClick && _ctx.loginOutClick(...args)))
                    }, "退出登录")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("a", {
              class: "ant-dropdown-link",
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createTextVNode(_toDisplayString(_ctx.userName) + " ", 1),
              _createVNode(_component_DownOutlined)
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_x_dialog, {
      title: "修改密码",
      visible: _ctx.dialogVisible,
      onOk: _ctx.confirm,
      onCancel: _cache[6] || (_cache[6] = ($event: any) => (_ctx.dialogVisible = false)),
      isFooter: null
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.formState,
          name: "basic",
          "label-col": { span: 8 },
          "wrapper-col": { span: 16 },
          autocomplete: "off"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "用户名",
              name: "account",
              rules: [{ required: true, message: '请输入用户名!' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.account,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.account) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "旧密码",
              name: "oldPassword",
              rules: [{ required: true, message: '请输入旧密码!' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input_password, {
                  value: _ctx.formState.oldPassword,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.oldPassword) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "新密码",
              name: "password",
              rules: [{ required: true, message: '请输入新密码!' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input_password, {
                  value: _ctx.formState.password,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.password) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { "wrapper-col": { offset: 8, span: 16 } }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  "html-type": "submit",
                  onClick: _ctx.confirm
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("确定")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "onOk"])
  ], 64))
}
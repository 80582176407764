
import { defineComponent, reactive, ref, watch } from "vue";
import {
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons-vue";
import XDialog from "@/components/XDialog.vue";
import { loginOut, resetPassword } from "@/api/api";
import { useRouter } from "vue-router";
import { removeToken } from "@/utils/auth";
import { message } from "ant-design-vue";

interface FormState {
  account: string;
  password: string;
  oldPassword: string;
}

export default defineComponent({
  components: {
    DownOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    XDialog,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const formState = reactive<FormState>({
      account: "",
      password: "",
      oldPassword: "",
    });
    const dialogVisible = ref();
    const router = useRouter();
    const ccollapsed = ref(false);
    const userName = ref(localStorage.getItem("USERNAME"));
    const editPassword = () => {
      formState.account = userName.value;
      dialogVisible.value = true;
    };
    watch(
      () => props.collapsed,
      (newVal, oldVal) => {
        ccollapsed.value = newVal;
      },
      { immediate: true }
    );
    const loginOutClick = () => {
      loginOut({}).then((res) => {
        router.replace("/");
        removeToken();
      });
    };
    const ccollapsedClick = () => {
      ccollapsed.value = !ccollapsed.value;
      ctx.emit("collapsedClick", ccollapsed.value);
    };
    const confirm = () => {
      resetPassword(formState).then((res) => {
        if (res.code === "200") {
          message.success("修改成功");
          router.replace("/login");
        }
      });
    };
    return {
      dialogVisible,
      ccollapsed,
      formState,
      userName,
      editPassword,
      loginOutClick,
      confirm,
      ccollapsedClick,
    };
  },
});


import Sider from "./components/Sider.vue";
import Header from "./components/Navbar.vue";
import { onMounted, ref } from "vue";
import { CloseOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    Sider,
    Header,
    CloseOutlined,
  },
  mounted() {},
  setup() {
    const collapsed = ref(false);
    const isH5 = ref(false);
    const visible = ref(true);
    onMounted(() => {
      if (document.documentElement.clientWidth < 450) {
        collapsed.value = true;
        isH5.value = true;
        visible.value = collapsed.value;
      }
      // window.onresize = function () {
      //   if (document.documentElement.clientWidth < 450) {
      //     collapsed.value = true;
      //     console.log(11)
      //     visible.value = collapsed.value;
      //   }
      // };
      leftDrawerClose();
    });
    const collapsedClick = (val) => {
      collapsed.value = val;
      visible.value = collapsed.value;
    };
    const leftDrawerClose = () => {
      visible.value = false;
      collapsed.value = false;
    };
    return {
      collapsed,
      isH5,
      visible,
      collapsedClick,
      leftDrawerClose,
    };
  },
};

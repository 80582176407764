<template>
  <div id="app2" ref="app2" :class="iscCross ? 'cross' : ''">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
export default {
  data() {
    return {
      locale: zhCN,
      iscCross: false,
    };
  },
  mounted() {
    // s(document.getElementById("app2"));
    if (document.documentElement.clientWidth < 450) {
      this.iscCross = true;
      document.getElementById("app").style.overflow = 'none'
    } else {
      this.iscCross = false;
      document.getElementById("app").style.overflow = 'hidden'
    }
    window.onresize = function () {
      if (document.documentElement.clientWidth < 450) {
        this.iscCross = true;
      } else {
        this.iscCross = false;
      }
    };
  },
};
</script>
<style>
#app {
  height: 100%;
  /* overflow: hidden; */
  /* min-width: 600px; */
}
.cross {
  /* width: 100vh;
  height: 100%;
  transform: rotate(90deg);
  transform-origin: 50vw 50vw;
  overflow: auto;
  padding-bottom: 80vw; */
}
</style>

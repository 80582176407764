<template>
  <icon>
    <template #component>
      <svg
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        :width="iconw"
        :height="iconh"
      >
        <path
          :fill="fillColor"
          d="M785.07008 409.6H716.8V273.07008C716.8 178.80064 640.39936 102.4 546.12992 102.4h-68.25984C383.60064 102.4 307.2 178.80064 307.2 273.07008V409.6h-68.27008c-37.5296 0-68.25984 30.74048-68.25984 68.27008v375.47008c0 37.5296 30.73024 68.25984 68.25984 68.25984h546.14016c37.5296 0 68.25984-30.73024 68.25984-68.25984V477.87008c0-37.5296-30.73024-68.27008-68.25984-68.27008zM546.12992 673.19808v111.872h-68.25984V673.19808c-20.33664-11.79648-34.14016-33.59744-34.14016-58.79808 0-37.66272 30.5664-68.25984 68.27008-68.25984s68.27008 30.59712 68.27008 68.25984c0 25.20064-13.80352 47.0016-34.14016 58.79808z m102.4-263.59808H375.47008V273.07008c0-56.53504 45.86496-102.4 102.4-102.4h68.25984c56.53504 0 102.4 45.86496 102.4 102.4V409.6z"
        ></path>
      </svg>
    </template>
  </icon>
</template>

<script>
import Icon from "@ant-design/icons-vue";
import { ref } from "vue";
export default {
  components: {
    Icon,
  },
  props: {
    color: {
      type: String,
      default: "#9c9ea4",
    },
    width: {
      type: String,
      default: "18",
    },
    height: {
      type: String,
      default: "18",
    },
  },
  setup(props) {
    const fillColor = ref(props.color);
    const iconw = ref(props.width);
    const iconh = ref(props.height);
    return {
      fillColor,
      iconw,
      iconh,
    };
  },
};
</script>

<style></style>

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import layout from "@/layout/index.vue";
import ReleaseData from "@/views/ReleaseData.vue";
import NovelData from "@/views/NovelData.vue";
import Login from "@/views/Login.vue";
import AppList from "@/views/AppList.vue";
import UserList from "@/views/UserList.vue";
import AccountList from "@/views/AccountList.vue";
import PayList from "@/views/PayList.vue";
import DelList from "@/views/DelList.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
    children: [
      {
        path: "/login",
        component: Login,
      },
    ],
  },
  {
    path: "/user",
    name: "layout",
    component: layout,
    redirect: "/user",
    children: [
      {
        path: "/user",
        component: UserList,
      },
      {
        path: "/accounts",
        component: AccountList,
      },
      {
        path: "/dellist",
        component: DelList,
      },
      {
        path: "/paylist",
        component: PayList,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

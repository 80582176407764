
import { defineComponent, onMounted, reactive, ref } from "vue";
import XAtable from "@/components/XATable.vue";
import {
  sysPayDelList,
  sysNovelConfigList,
  sysPayDelListdel,
  sysAdsSave,
  userQueryPageList,
} from "@/api/api";
import XDialog from "@/components/XDialog.vue";
import type { Rule } from "ant-design-vue/es/form";
import { message, type FormInstance } from "ant-design-vue";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

type RangeValue = [Dayjs, Dayjs];

interface SearchFormState {
  // appId: String | Number;
  // time: RangeValue;
  accountState: String;
  adsId: String;
  userId: String;
  frozenState: String;
  pageNo: Number;
  pageSize: Number;
}

interface FormState {
  amount: String;
}
export default {
  components: {
    XAtable,
    XDialog,
  },
  setup() {
    const searchFormState = reactive<SearchFormState>({
      frozenState: "",
      accountState: "",
      adsId: "",
      userId: "",
      pageNo: 1,
      pageSize: 10,
    });
    const formState = reactive<FormState>({
      amount: "",
    });

    const isAdd = ref(1);
    const dislogVisible = ref(false);
    const addLoading = ref(false);
    const pageNo = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const appList = ref([]);
    const dateFormat = ref("YYYY-MM-DD");
    const loading = ref(false);
    const columns = reactive([
      {
        title: "广告账户ID",
        key: "adsId",
        dataIndex: "adsId",
      },
      {
        title: "广告账号名称",
        key: "adsName",
        dataIndex: "adsName",
      },
      {
        title: "开户主体",
        key: "bmName",
        dataIndex: "bmName",
      },
      {
        title: "子公司",
        key: "company",
        dataIndex: "company",
      },
      //   {
      //     title: "冻结状态",
      //     key: "frozenState",
      //     dataIndex: "frozenState",
      //     slots: {
      //       customRender: "frozenState",
      //     },
      //   },
      {
        title: "退还余额",
        key: "delState",
        dataIndex: "delState",
        slots: {
          customRender: "delState",
        },
      },
      {
        title: "用户",
        key: "userId",
        dataIndex: "userId",
        slots: {
          customRender: "userId",
        },
      },
      {
        title: "累积充值金额(￥)",
        key: "totalAmount",
        dataIndex: "totalAmount",
      },
      {
        title: "操作",
        key: "control",
        dataIndex: "control",
        slots: {
          customRender: "control",
        },
      },
    ]);
    const tableData = ref([]);
    const formRef = ref<FormInstance>();
    const searchFormRef = ref<FormInstance>();
    const userList = ref([]);
    const curRow = ref<any>({});
    const search = () => {
      searchFormState.pageNo = 1;
      getData();
    };
    const resetForm = () => {
      searchFormState.pageNo = 1;
      searchFormRef.value.resetFields();
      getData();
    };
    const getAppList = () => {
      loading.value = true;
      sysNovelConfigList({}).then((res) => {
        appList.value = res.data;
        // searchFormState.appId = res.data[0].id;
        getData();
      });
    };
    const getData = () => {
      loading.value = true;
      sysPayDelList(searchFormState).then((res) => {
        loading.value = false;
        total.value = res.data.total;
        tableData.value = res.data.records;
      });
    };
    const pageNoChange = (val: number) => {
      searchFormState.pageNo = val;
      getData();
    };
    const onShowSizeChange = (current: any, size: number) => {
      searchFormState.pageSize = size;
      getData();
    };
    const selectChange = () => {
      searchFormState.pageNo = 1;
      getData();
    };
    const confirm = async () => {
      sysPayDelListdel({
        id: curRow.value.id,
        total: Number(formState.amount),
      }).then((res) => {
        if (res.code === "200") {
          dislogVisible.value = false;
          message.success("退回成功！");
          getData();
        }
      });
    };
    const getUserListData = () => {
      userQueryPageList({}).then((res) => {
        userList.value = res.data.records;
        getData();
      });
    };

    const confirmDel = (row: any) => {
      dislogVisible.value = true;
      curRow.value = row;
    };
    const cancelDel = () => {};

    const limitAmount = (event: any) => {
      formState.amount = event.target.value
        .replace(/[^\d.]/g, "")
        // 限制只有一个小数点
        .replace(/^\./g, "")
        .replace(/\.{2,}/g, ".")
        // 限制小数点后只能有两位
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".")
        .replace(/^(\-)*(\d+)\.(\d{2}).*$/, "$1$2.$3");
    };

    onMounted(() => {
      getUserListData();
    });
    return {
      userList,
      isAdd,
      formRef,
      dislogVisible,
      confirm,
      searchFormState,
      searchFormRef,
      formState,
      addLoading,
      columns,
      appList,
      tableData,
      pageNo,
      dateFormat,
      total,
      loading,
      search,
      resetForm,
      pageNoChange,
      onShowSizeChange,
      selectChange,
      confirmDel,
      cancelDel,
      limitAmount,
    };
  },
};

<template>
  <icon>
    <template #component>
      <svg
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        :width="iconw"
        :height="iconh"
      >
        <path
          :fill="fillColor"
          d="M500 128.8c-95.2 5.6-173.6 83.2-180 178.4-7.2 112 80.8 205.6 191.2 205.6 106.4 0 192-86.4 192-192 0.8-110.4-92-198.4-203.2-192zM512 575.2c-128 0-383.2 64-383.2 192v96c0 17.6 14.4 32 32 32h702.4c17.6 0 32-14.4 32-32V766.4c0-127.2-255.2-191.2-383.2-191.2z"
        ></path>
      </svg>
    </template>
  </icon>
</template>

<script>
import Icon from "@ant-design/icons-vue";
import { ref } from "vue";
export default {
  components: {
    Icon,
  },
  props: {
    color: {
      type: String,
      default: "#9c9ea4",
    },
    width: {
      type: String,
      default: "18",
    },
    height: {
      type: String,
      default: "18",
    },
  },
  setup(props) {
    const fillColor = ref(props.color);
    const iconw = ref(props.width);
    const iconh = ref(props.height);
    return {
      fillColor,
      iconw,
      iconh,
    };
  },
};
</script>

<style></style>

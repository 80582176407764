
import { defineComponent, onMounted, reactive, ref } from "vue";
import XAtable from "@/components/XATable.vue";
import {
  sysAdsQueryList,
  sysNovelConfigList,
  userAddAppUser,
  sysAdsSave,
  userQueryPageList,
  bmQueryList,
} from "@/api/api";
import XDialog from "@/components/XDialog.vue";
import type { Rule } from "ant-design-vue/es/form";
import type { FormInstance } from "ant-design-vue";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
type RangeValue = [Dayjs, Dayjs];

interface SearchFormState {
  // appId: String | Number;
  // time: RangeValue;
  accountState: String;
  adsId: String;
  userId: String;
  frozenState: String;
  pageNo: Number;
  pageSize: Number;
}

interface FormState {
  accountState: Number;
  adsId: String;
  adsName: String;
  bmName: String;
  //   company: String;
  frozenState: Number;
  id: String;
  userId: String;
}
export default {
  components: {
    XAtable,
    XDialog,
  },
  setup() {
    const searchFormState = reactive<SearchFormState>({
      frozenState: "",
      accountState: "",
      adsId: "",
      userId: "",
      pageNo: 1,
      pageSize: 10,
    });
    const formState = reactive<FormState>({
      accountState: 1,
      adsId: "",
      adsName: "",
      bmName: "",
      //   company: "",
      frozenState: 0,
      id: null,
      userId: "",
    });

    const isAdd = ref(1);
    const dislogVisible = ref(false);
    const addLoading = ref(false);
    const pageNo = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const appList = ref([]);
    const dateFormat = ref("YYYY-MM-DD");
    const loading = ref(false);
    const columns = reactive([
      {
        title: "广告账户ID",
        key: "adsId",
        dataIndex: "adsId",
      },
      {
        title: "广告账号名称",
        key: "adsName",
        dataIndex: "adsName",
      },
      {
        title: "开户主体",
        key: "bmName",
        dataIndex: "bmName",
      },
      {
        title: "子公司",
        key: "company",
        dataIndex: "company",
      },
      {
        title: "冻结状态",
        key: "frozenState",
        dataIndex: "frozenState",
        slots: {
          customRender: "frozenState",
        },
      },
      {
        title: "账号状态",
        key: "accountState",
        dataIndex: "accountState",
        slots: {
          customRender: "accountState",
        },
      },
      {
        title: "用户",
        key: "userId",
        dataIndex: "userId",
        slots: {
          customRender: "userId",
        },
      },
      {
        title: "累积充值金额(￥)",
        key: "totalAmount",
        dataIndex: "totalAmount",
      },
      {
        title: "操作",
        key: "control",
        dataIndex: "control",
        slots: {
          customRender: "control",
        },
      },
    ]);
    const bmColumns = reactive([
      {
        title: "广告账户ID",
        key: "adsId",
        dataIndex: "adsId",
      },
      {
        title: "广告账户名称",
        key: "adsName",
        dataIndex: "adsName",
      },
      {
        title: "BMID",
        key: "bmId",
        dataIndex: "bmId",
      },{
        title: "权限",
        key: "auth",
        dataIndex: "auth",
      },
      {
        title: "状态",
        key: "delFlag",
        dataIndex: "delFlag",
        slots: {
          customRender: "delFlag",
        },
      },
    ]);
    const tableData = ref([]);
    const bmTableData = ref([]);
    const formRef = ref<FormInstance>();
    const searchFormRef = ref<FormInstance>();
    const userList = ref([]);
    const bmDislogVisible = ref(false);
    const curRow = ref();
    const bmLoading = ref(false)

    const search = () => {
      searchFormState.pageNo = 1;
      getData();
    };
    const resetForm = () => {
      searchFormState.pageNo = 1;
      searchFormRef.value.resetFields();
      getData();
    };
    const getAppList = () => {
      loading.value = true;
      sysNovelConfigList({}).then((res) => {
        appList.value = res.data;
        // searchFormState.appId = res.data[0].id;
        getData();
      });
    };
    const getData = () => {
      loading.value = true;
      sysAdsQueryList(searchFormState).then((res) => {
        loading.value = false;
        total.value = res.data.total;
        tableData.value = res.data.records;
      });
    };
    const pageNoChange = (val) => {
      searchFormState.pageNo = val;
      getData();
    };
    const onShowSizeChange = (current, size) => {
      pageSize.value = size;
      getData();
    };
    const selectChange = () => {
      pageNo.value = 1;
      getData();
    };
    const addClick = () => {
      isAdd.value = 1;
      dislogVisible.value = true;
      formState.accountState = 1;
      formState.adsId = "";
      formState.adsName = "";
      formState.bmName = "";
      //   formState.company = "";
      formState.frozenState = 0;
      formState.id = null;
      formState.userId = "";
    };
    const editClick = (row: any) => {
      isAdd.value = 0;
      for (const key in formState) {
        formState[key] = row[key];
      }
      dislogVisible.value = true;
    };
    const confirm = async () => {
      try {
        const values = await formRef.value.validateFields();
        addLoading.value = true;

        if (isAdd.value === 1) {
          formState.id = null;
        }
        sysAdsSave(formState).then((res) => {
          addLoading.value = false;
          if (res.code === "200") {
            dislogVisible.value = false;
            getData();
          }
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const getUserListData = () => {
      userQueryPageList({}).then((res) => {
        userList.value = res.data.records;
        getData();
      });
    };
    const getBmListData = () => {
      bmLoading.value = true
      bmQueryList({ adsId: curRow.value.adsId}).then(
        (res) => {
          bmLoading.value = false
          bmTableData.value = res.data.records;
        }
      );
    };
    const lookBmClick = (row: any) => {
      curRow.value = row;
      bmDislogVisible.value = true;
      getBmListData();
    };
    onMounted(() => {
      getUserListData();
    });
    return {
      userList,
      isAdd,
      formRef,
      dislogVisible,
      addClick,
      confirm,
      searchFormState,
      searchFormRef,
      formState,
      addLoading,
      columns,
      appList,
      tableData,
      pageNo,
      dateFormat,
      total,
      loading,
      bmDislogVisible,
      bmColumns,
      bmLoading,
      bmTableData,
      search,
      resetForm,
      pageNoChange,
      onShowSizeChange,
      selectChange,
      editClick,
      lookBmClick,
    };
  },
};

<template>
  <a-table
    :dataSource="tableData"
    :columns="column"
    :bordered="false"
    :scroll="scroll"
    :loading="tableLoading"
    :pagination="false"
  >
    <template v-slot:headerCell="{ column }">
      <slot name="headerCell" :column="column"> </slot>
    </template>
    <template
      v-for="(item, index) in slots"
      :key="index"
      #[item.slots.customRender]="row"
    >
      <div v-if="item.slots">
        <slot :name="item.slots.customRender" :scope="row"></slot>
      </div>
    </template>
    <template v-slot:bodyCell="{ column, record ,index}">
      <slot v-if="!column.isMinute" :name="column.key" :record="record" :index="index"> </slot>
      <slot v-else> {{ column.symbol }}{{ record[column.key] / 100 }} </slot>
    </template>
  </a-table>
</template>
<script>
import { ref, watch, computed, onMounted } from "vue";
export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    scroll: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const column = ref(props.columns);
    const tableData = ref(props.dataSource);
    const tableLoading = ref(props.loading);
    watch(
      () => props.columns,
      (val) => {
        column.value = val;
      }
    );
    watch(
      () => props.dataSource,
      (val) => {
        tableData.value = val;
      }
    );
    watch(
      () => props.loading,
      (val) => {
        tableLoading.value = val;
      }
    );
    onMounted(() => {
      props.columns.filter((vals) => {
        return vals.slots;
      });
    });
    const slots = computed(() => {
      return props.columns.filter((val) => {
        return val.slots;
      });
    });
    return {
      column,
      tableData,
      slots,
      tableLoading,
    };
  },
};
</script>

<style></style>

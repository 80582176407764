import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "searchFrom" }
const _hoisted_2 = { style: {"margin-top":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_XAtable = _resolveComponent("XAtable")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_x_dialog = _resolveComponent("x-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_form, {
        name: "customized_form_controls",
        layout: "inline",
        model: $setup.searchFormState,
        ref: "searchFormRef"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            name: "adsId",
            label: "广告账户ID"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: $setup.searchFormState.adsId,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchFormState.adsId) = $event)),
                placeholder: "广告账户ID"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            name: "userId",
            label: "用户ID"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                placeholder: "请选择用户",
                value: $setup.searchFormState.userId,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.searchFormState.userId) = $event)),
                style: {"min-width":"120px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode("全部")
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userList, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: item.id,
                      key: item.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                style: {"margin-right":"10px"},
                onClick: $setup.resetForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode("重置")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: $setup.search,
                style: {"margin-right":"10px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode("搜索")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_XAtable, {
        columns: $setup.columns,
        dataSource: $setup.tableData,
        loading: $setup.loading,
        scroll: { x: 600 }
      }, {
        frozenState: _withCtx(({ scope }) => [
          _createElementVNode("span", null, _toDisplayString(scope.record.frozenState === 1 ? "冻结" : "正常"), 1)
        ]),
        delState: _withCtx(({ scope }) => [
          _createElementVNode("span", null, _toDisplayString(scope.record.delState === 1 ? "已退回" : "未处理"), 1)
        ]),
        userId: _withCtx(({ scope }) => [
          _createElementVNode("span", null, _toDisplayString($setup.userList.filter((val) => val.id === Number(scope.record.userId))
            .length
            ? $setup.userList.filter(
                (val) => val.id === Number(scope.record.userId)
              )[0].name
            : ""), 1)
        ]),
        control: _withCtx(({ scope }) => [
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            style: {"margin-left":"10px"},
            onClick: ($event: any) => ($setup.confirmDel(scope.record))
          }, {
            default: _withCtx(() => [
              _createTextVNode("退还余额")
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      }, 8, ["columns", "dataSource", "loading"]),
      _createVNode(_component_a_pagination, {
        current: $setup.pageNo,
        "onUpdate:current": _cache[2] || (_cache[2] = ($event: any) => (($setup.pageNo) = $event)),
        onChange: $setup.pageNoChange,
        total: $setup.total,
        hideOnSinglePage: "",
        onShowSizeChange: $setup.onShowSizeChange,
        style: {"margin-top":"5px"}
      }, null, 8, ["current", "onChange", "total", "onShowSizeChange"])
    ]),
    _createVNode(_component_x_dialog, {
      title: "退回余额",
      visible: $setup.dislogVisible,
      onCancel: _cache[4] || (_cache[4] = ($event: any) => ($setup.dislogVisible = false)),
      onConfirm: $setup.confirm,
      loading: $setup.addLoading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: $setup.formState,
          ref: "formRef",
          labelCol: { span: 4, offset: 0 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "余额",
              name: "amount",
              rules: [{ required: true, message: '请输入余额!' }],
              onInput: $setup.limitAmount
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: "请输入余额",
                  value: $setup.formState.amount,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.amount) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["onInput"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "onConfirm", "loading"])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_Sider = _resolveComponent("Sider")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_layout, { style: {"min-height":"100vh"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_header, { style: {"height":"50px","line-height":"50px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_Header, {
              collapsed: $setup.collapsed,
              onCollapsedClick: $setup.collapsedClick
            }, null, 8, ["collapsed", "onCollapsedClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_layout, null, {
          default: _withCtx(() => [
            (!$setup.isH5)
              ? (_openBlock(), _createBlock(_component_a_layout_sider, {
                  key: 0,
                  breakpoint: "lg",
                  "collapsed-width": $setup.isH5 ? 0 : 80,
                  collapsed: $setup.collapsed,
                  "onUpdate:collapsed": _cache[0] || (_cache[0] = ($event: any) => (($setup.collapsed) = $event)),
                  style: {"background":"#fff"},
                  trigger: null
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Sider)
                  ]),
                  _: 1
                }, 8, ["collapsed-width", "collapsed"]))
              : _createCommentVNode("", true),
            _createVNode(_component_a_layout_content, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass($setup.isH5 ? 'main-content' : 'main-content ish5')
                }, [
                  _createVNode(_component_router_view)
                ], 2)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    ($setup.isH5)
      ? (_openBlock(), _createBlock(_component_a_drawer, {
          key: 0,
          placement: "left",
          closable: false,
          visible: $setup.visible,
          onClose: $setup.leftDrawerClose,
          maskClosable: true,
          width: "200px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Sider, { onClose: $setup.leftDrawerClose }, null, 8, ["onClose"])
          ]),
          _: 1
        }, 8, ["visible", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}
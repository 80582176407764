import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "searchFrom" }
const _hoisted_2 = { style: {"margin-top":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_XAtable = _resolveComponent("XAtable")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_x_dialog = _resolveComponent("x-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_form, {
        name: "customized_form_controls",
        layout: "inline",
        model: $setup.searchFormState
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { label: "手机号" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: $setup.searchFormState.mobile,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchFormState.mobile) = $event)),
                placeholder: "输入手机号"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                style: {"margin-right":"10px"},
                onClick: $setup.resetForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode("重置")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: $setup.search,
                style: {"margin-right":"10px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode("搜索")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: $setup.addClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode("添加")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_XAtable, {
        columns: $setup.columns,
        dataSource: $setup.tableData,
        loading: $setup.loading,
        scroll: { x: 600 }
      }, {
        status: _withCtx(({ scope }) => [
          _createElementVNode("span", null, _toDisplayString(scope.record.status === 1 ? "开启" : "禁用"), 1)
        ]),
        control: _withCtx(({ scope }) => [
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            onClick: ($event: any) => ($setup.editClick(scope.record)),
            style: {"margin-right":"10px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode("编辑")
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            onClick: ($event: any) => ($setup.topUptoUser(scope.record))
          }, {
            default: _withCtx(() => [
              _createTextVNode("充值")
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      }, 8, ["columns", "dataSource", "loading"]),
      _createVNode(_component_a_pagination, {
        current: $setup.pageNo,
        "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => (($setup.pageNo) = $event)),
        onChange: $setup.pageNoChange,
        total: $setup.total,
        hideOnSinglePage: "",
        onShowSizeChange: $setup.onShowSizeChange,
        style: {"margin-top":"5px"}
      }, null, 8, ["current", "onChange", "total", "onShowSizeChange"])
    ]),
    _createVNode(_component_x_dialog, {
      title: $setup.isAdd ? '添加' : '编辑',
      visible: $setup.dislogVisible,
      onCancel: _cache[6] || (_cache[6] = ($event: any) => ($setup.dislogVisible = false)),
      onConfirm: $setup.confirm,
      loading: $setup.addLoading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: $setup.formState,
          ref: "formRef",
          labelCol: { span: 4, offset: 0 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "用户名",
              name: "name",
              rules: [{ required: true, message: '请输入用户名!' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: "请输入用户名",
                  value: $setup.formState.name,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "公司",
              name: "company",
              rules: [{ required: true, message: '请输入公司名称!' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: "请输入公司名",
                  value: $setup.formState.company,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.company) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "手机",
              name: "mobile",
              rules: [{ required: true, message: '请输入手机号码!' }],
              extra: "默认密码为手机号后6位"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: "请输入手机号",
                  disabled: !$setup.isAdd,
                  value: $setup.formState.mobile,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (($setup.formState.mobile) = $event))
                }, null, 8, ["disabled", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "状态" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  name: "isOther",
                  value: $setup.formState.status,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (($setup.formState.status) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, { value: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode("启用")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, { value: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode("禁用")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["title", "visible", "onConfirm", "loading"]),
    _createVNode(_component_x_dialog, {
      title: "充值",
      visible: $setup.topUpDislogVisible,
      onCancel: _cache[8] || (_cache[8] = ($event: any) => ($setup.topUpDislogVisible = false)),
      onConfirm: $setup.topUpConfirm,
      loading: $setup.topUpLoading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: $setup.formState,
          ref: "formRef",
          labelCol: { span: 4, offset: 0 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "金额",
              name: "wallet",
              rules: [{ required: true, message: '请输入充值金额!' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: "请输入充值金额",
                  value: $setup.formState.wallet,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (($setup.formState.wallet) = $event)),
                  onInput: $setup.limitAmount,
                  prefix: "$"
                }, null, 8, ["value", "onInput"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "onConfirm", "loading"])
  ], 64))
}
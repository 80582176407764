
import { defineComponent, onMounted, reactive, ref } from "vue";
import XAtable from "@/components/XATable.vue";
import {
  sysPayQueryList,
  sysNovelConfigList,
  userAddAppUser,
  sysAdsSave,
  userQueryPageList,
  sysPayToAds,
  sysPayReAds,
} from "@/api/api";
import XDialog from "@/components/XDialog.vue";
import type { Rule } from "ant-design-vue/es/form";
import { FormInstance, message, Modal } from "ant-design-vue";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
type RangeValue = [Dayjs, Dayjs];

interface SearchFormState {
  orderNo: String;
  userId: String;
  payResult: String;
  pageNo: Number;
  pageSize: Number;
  adsId: String;
}

interface FormState {
  accountState: Number;
  orderNo: String;
  adsName: String;
  bmName: String;
  company: String;
  payResult: Number;
  id: String;
  userId: String;
}
export default {
  components: {
    XAtable,
    XDialog,
  },
  setup() {
    const searchFormState = reactive<SearchFormState>({
      adsId: "",
      payResult: "",
      orderNo: "",
      userId: "",
      pageNo: 1,
      pageSize: 10,
    });
    const formState = reactive<FormState>({
      accountState: 1,
      orderNo: "",
      adsName: "",
      bmName: "",
      company: "",
      payResult: 0,
      id: null,
      userId: "",
    });

    const isAdd = ref(1);
    const dislogVisible = ref(false);
    const addLoading = ref(false);
    const pageNo = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const appList = ref([]);
    const dateFormat = ref("YYYY-MM-DD");
    const loading = ref(false);
    const columns = reactive([
      {
        title: "订单编号",
        key: "outTradeNo",
        dataIndex: "outTradeNo",
      },
      {
        title: "广告账户ID",
        key: "adsId",
        dataIndex: "adsId",
      },
      {
        title: "支付时间",
        key: "payTime",
        dataIndex: "payTime",
      },
      {
        title: "钱包抵扣",
        key: "isUseWallet",
        dataIndex: "isUseWallet",
        slots: {
          customRender: "isUseWallet",
        },
      },
      {
        title: "抵扣金额(￥)",
        key: "walletOutTotal",
        dataIndex: "walletOutTotal",
      },
      {
        title: "总金额(￥)",
        key: "total",
        dataIndex: "total",
      },
      {
        title: "支付类型",
        key: "type",
        dataIndex: "type",
        slots: {
          customRender: "type",
        },
      },
      {
        title: "支付结果",
        key: "payResult",
        dataIndex: "payResult",
        slots: {
          customRender: "payResult",
        },
      },
      {
        title: "操作",
        key: "control",
        dataIndex: "control",
        slots: {
          customRender: "control",
        },
      },
    ]);
    const tableData = ref([]);
    const formRef = ref<FormInstance>();
    const searchFormRef = ref<FormInstance>();
    const userList = ref([]);

    const search = () => {
      searchFormState.pageNo = 1;
      getData();
    };
    const resetForm = () => {
      searchFormState.pageNo = 1;
      searchFormRef.value.resetFields();
      getData();
    };
    const getAppList = () => {
      loading.value = true;
      sysNovelConfigList({}).then((res) => {
        appList.value = res.data;
        // searchFormState.appId = res.data[0].id;
        getData();
      });
    };
    const getData = () => {
      loading.value = true;
      sysPayQueryList(searchFormState).then((res) => {
        loading.value = false;
        total.value = res.data.total;
        tableData.value = res.data.records;
      });
    };
    const pageNoChange = (val: number) => {
      searchFormState.pageNo = val;
      getData();
    };
    const onShowSizeChange = (current, size: number) => {
      searchFormState.pageSize = size;
      getData();
    };
    const selectChange = () => {
      searchFormState.pageNo = 1;
      getData();
    };
    const addClick = () => {
      isAdd.value = 1;
      dislogVisible.value = true;
      formState.accountState = 1;
      formState.orderNo = "";
      formState.adsName = "";
      formState.bmName = "";
      formState.company = "";
      formState.payResult = 0;
      formState.id = null;
      formState.userId = "";
    };
    const editClick = (row: any) => {
      isAdd.value = 0;
      for (const key in formState) {
        formState[key] = row[key];
      }
      dislogVisible.value = true;
    };
    const confirm = async () => {
      try {
        const values = await formRef.value.validateFields();
        addLoading.value = true;

        if (isAdd.value === 1) {
          formState.id = null;
        }
        sysAdsSave(formState).then((res) => {
          addLoading.value = false;
          if (res.code === "200") {
            dislogVisible.value = false;
            getData();
          }
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const confirmToPay = (row: any) => {
      sysPayToAds({ id: row.id }).then((res) => {
        if (res.code === "200") {
          getData();
        }
      });
    };
    const cancelToPay = (row) => {
      Modal.confirm({
        title: "提示",
        content: "确定退回?",
        onOk() {
          sysPayReAds({ id: row.id }).then((res) => {
            if (res.code === "200") {
              message.success("退回成功！");
              getData();
            }
          });
        },
        onCancel() {},
      });
    };
    const getUserListData = () => {
      userQueryPageList({}).then((res) => {
        userList.value = res.data.records;
        getData();
      });
    };
    onMounted(() => {
      getUserListData();
    });
    return {
      userList,
      isAdd,
      formRef,
      dislogVisible,
      addClick,
      confirm,
      searchFormState,
      searchFormRef,
      formState,
      addLoading,
      columns,
      appList,
      tableData,
      pageNo,
      dateFormat,
      total,
      loading,
      search,
      resetForm,
      pageNoChange,
      onShowSizeChange,
      selectChange,
      editClick,
      confirmToPay,
      cancelToPay,
    };
  },
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "searchFrom" }
const _hoisted_2 = { style: {"margin-top":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_XAtable = _resolveComponent("XAtable")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_x_dialog = _resolveComponent("x-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_form, {
        name: "customized_form_controls",
        layout: "inline",
        model: $setup.searchFormState,
        ref: "searchFormRef"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            name: "adsId",
            label: "广告账户ID"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: $setup.searchFormState.adsId,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchFormState.adsId) = $event)),
                placeholder: "广告账户ID"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            name: "userId",
            label: "用户ID"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                placeholder: "请选择用户",
                value: $setup.searchFormState.userId,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.searchFormState.userId) = $event)),
                style: {"min-width":"120px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode("全部")
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userList, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      value: item.id,
                      key: item.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            name: "frozenstate",
            label: "冻结状态"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: $setup.searchFormState.frozenState,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (($setup.searchFormState.frozenState) = $event)),
                style: {"min-width":"120px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode("全部")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: "0" }, {
                    default: _withCtx(() => [
                      _createTextVNode("正常")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: "1" }, {
                    default: _withCtx(() => [
                      _createTextVNode("冻结")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            name: "accountstate",
            label: "账号状态"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: $setup.searchFormState.accountState,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.searchFormState.accountState) = $event)),
                style: {"min-width":"120px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode("全部")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: "1" }, {
                    default: _withCtx(() => [
                      _createTextVNode("使用中")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: "2" }, {
                    default: _withCtx(() => [
                      _createTextVNode("已停用")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                style: {"margin-right":"10px"},
                onClick: $setup.resetForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode("重置")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: $setup.search,
                style: {"margin-right":"10px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode("搜索")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: $setup.addClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode("添加")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_XAtable, {
        columns: $setup.columns,
        dataSource: $setup.tableData,
        loading: $setup.loading,
        scroll: { x: 600 }
      }, {
        frozenState: _withCtx(({ scope }) => [
          _createElementVNode("span", null, _toDisplayString(scope.record.frozenState === 1 ? "冻结" : "正常"), 1)
        ]),
        accountState: _withCtx(({ scope }) => [
          _createElementVNode("span", null, _toDisplayString(scope.record.accountState === 1 ? "使用中" : "已停用"), 1)
        ]),
        userId: _withCtx(({ scope }) => [
          _createElementVNode("span", null, _toDisplayString($setup.userList.filter((val) => val.id === Number(scope.record.userId))
            .length
            ? $setup.userList.filter(
                (val) => val.id === Number(scope.record.userId)
              )[0].name
            : ""), 1)
        ]),
        control: _withCtx(({ scope }) => [
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            onClick: ($event: any) => ($setup.editClick(scope.record))
          }, {
            default: _withCtx(() => [
              _createTextVNode("编辑")
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            onClick: ($event: any) => ($setup.lookBmClick(scope.record)),
            style: {"margin-left":"10px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode("查看BM")
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      }, 8, ["columns", "dataSource", "loading"]),
      _createVNode(_component_a_pagination, {
        current: $setup.pageNo,
        "onUpdate:current": _cache[4] || (_cache[4] = ($event: any) => (($setup.pageNo) = $event)),
        onChange: $setup.pageNoChange,
        total: $setup.total,
        hideOnSinglePage: "",
        onShowSizeChange: $setup.onShowSizeChange,
        style: {"margin-top":"5px"}
      }, null, 8, ["current", "onChange", "total", "onShowSizeChange"])
    ]),
    _createVNode(_component_x_dialog, {
      title: $setup.isAdd ? '添加' : '编辑',
      visible: $setup.dislogVisible,
      onCancel: _cache[11] || (_cache[11] = ($event: any) => ($setup.dislogVisible = false)),
      onConfirm: $setup.confirm,
      loading: $setup.addLoading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: $setup.formState,
          ref: "formRef",
          labelCol: { span: 4, offset: 0 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "账户名称",
              name: "adsName",
              rules: [{ required: true, message: '请输入账户名称!' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: "请输入账户名称",
                  value: $setup.formState.adsName,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (($setup.formState.adsName) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "账户ID",
              name: "adsId",
              rules: [{ required: true, message: '请输入账户ID!' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: "请输入账户ID",
                  value: $setup.formState.adsId,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (($setup.formState.adsId) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "开户主体",
              name: "bmName",
              rules: [{ required: true, message: '请输入开户主体!' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: "请输入开户主体",
                  value: $setup.formState.bmName,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (($setup.formState.bmName) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "用户",
              name: "userId",
              rules: [{ required: true, message: '请选择用户!' }]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  placeholder: "请选择用户",
                  value: $setup.formState.userId,
                  "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (($setup.formState.userId) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userList, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        value: item.id,
                        key: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "账号状态" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  name: "accountState",
                  value: $setup.formState.accountState,
                  "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => (($setup.formState.accountState) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, { value: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode("使用中")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, { value: 2 }, {
                      default: _withCtx(() => [
                        _createTextVNode("已停用")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "冻结状态" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  name: "frozenState",
                  value: $setup.formState.frozenState,
                  "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => (($setup.formState.frozenState) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, { value: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode("正常")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, { value: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode("冻结")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["title", "visible", "onConfirm", "loading"]),
    _createVNode(_component_x_dialog, {
      title: "查看BM",
      visible: $setup.bmDislogVisible,
      onCancel: _cache[12] || (_cache[12] = ($event: any) => ($setup.bmDislogVisible = false)),
      isFooter: false,
      width: 800
    }, {
      default: _withCtx(() => [
        _createVNode(_component_XAtable, {
          columns: $setup.bmColumns,
          dataSource: $setup.bmTableData,
          loading: $setup.bmLoading,
          scroll: { x: 600 }
        }, {
          delFlag: _withCtx(({ scope }) => [
            _createElementVNode("span", null, _toDisplayString(scope.record.delFlag === 1 ? "已解绑" : "正常"), 1)
          ]),
          _: 1
        }, 8, ["columns", "dataSource", "loading"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}